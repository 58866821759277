import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Works from "../components/works"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"

const FragorPage = () => (
  <Layout hero={false}>
    <SEO title="Vanliga frågor" />

    <Row>
      <Col>
        <h1 className="mt-4 mb-3">Vanliga frågor</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={4} className="order-sm-2 mb-5">
        <ListGroup variant="flush">
          <ListGroup.Item action href="#ny-varje-manad">
            Är det en ny sorts kaffe varje månad?
          </ListGroup.Item>
          <ListGroup.Item action href="#hela-bonor">
            Är det alltid hela bönor?
          </ListGroup.Item>
          <ListGroup.Item action href="#hur-mycket">
            Hur mycket kaffe får jag gång?
          </ListGroup.Item>
          <ListGroup.Item action href="#fair-ekologiskt">
            KRAV, Ekologiskt eller Fairtrade?
          </ListGroup.Item>
          <ListGroup.Item action href="#vad-for-kaffe">
            Vad för kaffe får jag?
          </ListGroup.Item>
          <ListGroup.Item action href="#bestamma">
          Kan jag bestämma vilket kaffe jag vill ha varje månad?
          </ListGroup.Item>
          <ListGroup.Item action href="#bestalla-mer">
            Jag gillade ett speciellt kaffe, kan jag beställa mer av det?
          </ListGroup.Item>
        </ListGroup>
      </Col>
      <Col xs={12} sm={8}>
        <h2 id="ny-varje-manad">Är det en ny sorts kaffe varje månad?</h2>
        <p>Ja, vi erbjuder på en ny kaffesort varje månad.</p>

        <h2 id="hela-bonor">Är det alltid hela bönor?</h2>
        <p>
          Nymalda kaffebönor ger bäst smak, och för att du ska kunna uppleva
          detta så skyckar vi hela bönor.
        </p>

        <h2 id="hur-mycket">Hur mycket kaffe får jag gång?</h2>
        <p>Påsen innehåller ca 150g bönor, vilket bli ca 10-15 koppar.</p>

        <h2 id="fair-ekologiskt">KRAV, Ekologiskt eller Fairtrade?</h2>
        <p>Något bra svar.</p>

        <h2 id="vad-for-kaffe">Vad för kaffe får jag?</h2>
        <p>Gemensamt för våra kaffeblandningar är följande:</p>
        <ul>
          <li>Handplockat</li>
          <li>Trumrost Slow roast</li>
          <li>Tvättat kaffe</li>
          <li>A-quality SHG (Strictly High Grown)</li>
        </ul>

        <h2 id="bestamma">Kan jag bestämma vilket kaffe jag vill ha varje månad?</h2>
        <p>
          I dagsläget kan du välja på våra olika{" "}
          <Link to="/smakpaket">smakpaket.</Link>
        </p>

        <h2 id="bestalla-mer">
          Jag gillade ett speciellt kaffe, kan jag beställa mer av det?
        </h2>
        <p>Hör av dig till oss så ser vi till att lösa det.</p>
      </Col>
    </Row>
    <Works />
  </Layout>
)

export default FragorPage
